import React from "react";
import { Button, Grid } from "@material-ui/core";
import { CommonTabList } from "../../../../../../components";
import Organisation from "./organisation";
import Criteria from "./criteria";
import Applicability from "./applicapility";

import { valitationFunc } from "../../../../../../utils";
import { withAllContexts } from "../../../../../../HOCs";
import { connect } from "react-redux";
import { actions } from "primary_care_admin_binder";

const option = [
  {
    label: "Option 1",
    value: "Option1",
  },
  {
    label: "Option 2",
    value: "Option2",
  },
];

const errorList = [
  "entityType",
  "entityName",
  "gender",
  "ageRange",
  "ageCategory",
];
class VitalApplicableForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entityType: "",
      entityName: "",
      specialCode: null,
      gender: null,
      diagnosisGroup: [],
      diagnosisTextChange: "",
      ageRange: null,
      ageCategory: null,
      minAge: "",
      ageMax: "",
      status: true,
      applicability: [],
      editData: null,
      error:{}
    };
    this.handleFormState = this.handleFormState.bind(this);
  }
  async componentDidMount() {
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: this.props.editData ? "Loading..." : "Fetching...",
    });
    //this.props.GET_ENTITY_NAME();
    await this.props.GET_ENTITY_NAME();
    await this.props.GET_ENTITY_TYPE_VITALAPP();
    await this.props.GENDER();
    //await this.props.SPECIALITY_CODE();
    //await this.props.DIAGNOSIS();
    await this.props.AGE_RANGES_CODE();
    await this.props.VITAL_CODE();
    //await this.props.SPECIALITY_CODE_ORG_ID({ org_id: 0 })

    if (this.props.editData) {
      const editData = this.props.editData;
      await this.props.EDIT_VITAL_APPLICABLE({ id: editData._id });
      let data = this.props.editvital.data;

      if (!this.props.editvital.error) {
        //for getting category dropdown based on agearange
        let ageRange = data[0].ageRange.value;

        let org_Id = data?.[0]?.entityName?.id;
        let entity_type_id = data?.[0]?.entityType?.value;
        await this.props.GET_ENTITY_NAME({list: entity_type_id})
        await this.props.SPECIALITY_CODE_ORG_ID({ org_id: org_Id })
        await this.props.AGE_RANGES_CATEGORY({ ageRangeId: ageRange });

        //for getting selected Category
        let ageRangeCategoryRes = data[0].ageCategoryres;
        let agecategory = this.props.agerangeCategory.data.find(
          ({ value: id1 }) =>
            ageRangeCategoryRes.some(({ _id: id2 }) => id2 === id1)
        );

        this.state = data[0];
        this.setState({
          ageCategory: agecategory,
          ...this.state,
        });
      } else {
        this.props.alert.setSnack("error");
      }
    }
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: false,
      message: "",
    });
  }

  //handle state
  handleFormState = async (name, value) => {
    let errorObj = JSON.parse(JSON.stringify(this.state.error));
    errorObj[name] = false;
    if (name === "ageRange" && value === null){
      this.state.ageCategory = ""
    }
    else if(name === "entityType" && value){
      await this.props.GET_ENTITY_NAME({list: value.value})
    }
     else if (name === "ageRange" && value) {
      await this.props.AGE_RANGES_CATEGORY({ ageRangeId: value.value });
    } else if (name === "diagnosisTextChange" && value.length > 3) {
      await this.props.DIAGNOSIS_MASTERS({ input_text: value });
    } else if (name === "entityName" && value) {
      await this.props.SPECIALITY_CODE_ORG_ID({ org_id: value.id });
      this.state.specialCode = ''
    }
      this.setState({ [name]: value, error: errorObj });
  };

  //save form
  saveForm = async () => {
    // let error = false;
    // errorList.map((val) => {
    //   if (!this.state[val]) {
    //     error = true;
    //   }
    // });
    let { error, errorState } = await valitationFunc(this.state, errorList);
    if (error) {
      this.props.alert.setSnack("mandatory");
        this.setState({
          ...this.state,
          error: errorState,
        });
    } else {
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: true,
        message: this.props.editData ? "Updating..." : "Adding...",
      });
      await this.props.SAVE_VITAL_APPLICABLE({ list: this.state });
     
      if (
        this.props.saveVitalApplicable?.data?.Code === 201 &&
        !this.props.saveVitalApplicable?.error
      ) {
        await this.props.READ_VITAL_APPLICABLE({page: this.props.vitalState.page, perPage: this.props.vitalState.perPage});
        let type =this.props.editData
             ? "update"
             : "success"
         this.props.alert.setSnack(type);
         this.props.closeForm();
      } else {
         this.props.alert.setSnack("error");
      }
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: false,
        message: "",
      });
      
    }
  };

  render() {
    const { closeForm = () => null, parent_id } = this.props;
    return (
      <React.Fragment>
        <CommonTabList
          parent_id={"vital_master_criteria"}
          backbtn
          backFun={closeForm}
          title={
            this.props.editData
              ? "Update Vitals Applicable Criteria"
              : "Add New Vitals Applicable Criteria"
          }
          list={[]}
        />
        <Grid
          id={`${parent_id}_Organisation_grid`}
          style={{ padding: "20px" }}>
          <Organisation
            parent_id={"vital_master_criteria_Organisation"}
            categoryOptions={option}
            unitAllowedOption={option}
            handleFormState={this.handleFormState}
            {...this.state}
          />
        </Grid>
        <Grid
          id={`${parent_id}_criteria_grid`}
          style={{ padding: "20px" }}>
          <Criteria
            parent_id={"vital_master_criteria_criteria"}
            diagnosisGroupOption={option}
            handleFormState={this.handleFormState}
            {...this.state}
          />
        </Grid>
        <Grid
          id={`${parent_id}_applicability_grid`}
          style={{ padding: "20px" }}>
          <Applicability
            parent_id={"criteria_applicability"}
            handleFormState={this.handleFormState}
            {...this.state}
          />
        </Grid>

        <Grid
          id={`${parent_id}_buttons_grid`}
          style={{ padding: "20px" }}>
          <Button
            id={`${parent_id}_Save_button`}
            onClick={() => this.saveForm()}
            style={{ float: "right" }}
            variant="contained"
            color="primary"
          >
            {this.props.editData ? "Update" : "Save"}
          </Button>
          <Button
            id={`${parent_id}_Cancel_button`}
            onClick={() => closeForm()}
            style={{ float: "right", marginRight: "20px" }}
            variant="outlined"
          >
            Cancel
          </Button>
        </Grid>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  editvital: state.vitalsApplicableSlice.edit_vital_applicable,
  agerangeCategory: state.vitalsApplicableSlice.ageranges_category,
  saveVitalApplicable: state.vitalsApplicableSlice.save_vital_applicable,
});
export default connect(
  mapStateToProps,
  actions
)(withAllContexts(VitalApplicableForm));
